@media (min-width: 1000px) and (max-width: 1024px) {
  .centered-toggle {
    display: block !important;
  }
  
  .collapse-hidden {
    display: none !important;
  }
  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .navbar-wrapper {
    position: relative !important;
    padding: 1rem;
    height: 1rem;
    width: 100%;
    background-color: transparent;
    box-shadow: none;

    .navbar {
      margin: 0 auto;
      background-color: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .nav-link {
      display: flex !important;
      justify-content: center !important;
      font-size: 1.2rem !important;
      margin: 0.1rem !important;
      color: #1a535c !important;
      background-color: white;
      width: 10rem;
      border-radius: 15px;
    }

    .centered-toggle {
      position: fixed; // Use 'fixed' instead of 'absolute'
      top: 1rem;
      left: 1rem;
      background-color: #1a535c;
      border: none;
      color: white !important;
      z-index: 1001;
    }
    

    .navbar-toggler {
      background-color: #1a535c;
      border: none;
      color: white !important;
      display: block;
      margin: 0 auto;
    }

    .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 4rem;
      left: 1rem;

      .nav {
        flex-direction: column;
        align-items: center !important;
        width: 100%;
      }

      .navbar-nav .nav-link {
        padding: 0.5rem 1rem;
        text-align: center !important;
      }
    }
  }
}

@media only screen and (max-width: 1792px) {

  .navbar-wrapper {
    width: 42%;
    margin-left: 33rem;
  }

  .desktop-nav {
    width: 170%;
    margin-left: 2.2rem !important;
  }

}

@media only screen and (max-width: 1680px) {

  .navbar-wrapper {
    width: 44%;
    margin-left: 28rem;
  }

  .desktop-nav {
    width: 170%;
    margin-left: 2.2rem !important;
  }

}

@media only screen and (max-width: 1528px) {

  .navbar-wrapper {
    width: 48%;
    margin-left: 26rem;
  }

  .desktop-nav {
    width: 170%;
    margin-left: 2.2rem !important;
  }

}

@media only screen and (max-width: 1366px) {

  .navbar-wrapper {
    width: 52%;
    margin-left: 20.5rem;
  }

  .desktop-nav {
    width: 170%;
    margin-left: 2.2rem !important;
  }

}

@media only screen and (max-width: 430px) {

  .navbar-wrapper {
    padding: 0.5rem;
    position: relative !important;
    padding: 1rem;
    height: 1rem;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    /* Add other styles specific to 320px devices */

    .navbar {
      flex-direction: column;
      margin: 0 auto;
      background-color: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      /* Add other styles specific to 320px devices */
    }

    .nav-link {
      display: flex !important;
      justify-content: center !important;
      font-size: 1rem !important;
      margin: 0rem !important;
      color: #1a535c !important;
      background-color: white;
      border: solid 2px #1A535C;
      width: 8rem;
      border-radius: 15px;
      /* Add other styles specific to 320px devices */
    }

    .centered-toggle {
      position: fixed; // Use 'fixed' instead of 'absolute'
      top: 1rem;
      left: 1rem;
      background-color: #1a535c;
      border: none;
      color: white !important;
      z-index: 1001;
      /* Add other styles specific to 320px devices */
    }

    .navbar-toggler {
      background-color: #1a535c;
      border: none;
      color: white !important;
      display: block;
      margin: 0 auto;
    }

    .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 4rem;
      left: 1rem;

      .nav {
        flex-direction: column;
        align-items: center !important;
        width: 100%;
      }

      .navbar-nav .nav-link {
        padding: 0.4rem 0.8rem;
        /* Add other styles specific to 320px devices */
      }
    }
  }

}

@media only screen and (max-width: 390px) {

  .navbar-wrapper {
    padding: 0.5rem;
    position: relative !important;
    padding: 1rem;
    height: 1rem;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    /* Add other styles specific to 320px devices */

    .navbar {
      flex-direction: column;
      margin: 0 auto;
      background-color: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      /* Add other styles specific to 320px devices */
    }

    .nav-link {
      display: flex !important;
      justify-content: center !important;
      font-size: 1rem !important;
      margin: 0rem !important;
      color: #1a535c !important;
      background-color: white;
      border: solid 2px #1A535C;
      width: 8rem;
      border-radius: 15px;
      /* Add other styles specific to 320px devices */
    }

    .centered-toggle {
      position: fixed; // Use 'fixed' instead of 'absolute'
      top: 1rem;
      left: 1rem;
      background-color: #1a535c;
      border: none;
      color: white !important;
      z-index: 1001;
      /* Add other styles specific to 320px devices */
    }

    .navbar-toggler {
      background-color: #1a535c;
      border: none;
      color: white !important;
      display: block;
      margin: 0 auto;
    }

    .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 4rem;
      left: 1rem;

      .nav {
        flex-direction: column;
        align-items: center !important;
        width: 100%;
      }

      .navbar-nav .nav-link {
        padding: 0.4rem 0.8rem;
        /* Add other styles specific to 320px devices */
      }
    }
  }

}

@media only screen and (max-width: 360px) {

  .navbar-wrapper {
    padding: 0.5rem;
    position: relative !important;
    padding: 1rem;
    height: 1rem;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    /* Add other styles specific to 320px devices */

    .navbar {
      flex-direction: column;
      margin: 0 auto;
      background-color: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      /* Add other styles specific to 320px devices */
    }

    .nav-link {
      display: flex !important;
      justify-content: center !important;
      font-size: 1rem !important;
      margin: 0.1rem !important;
      color: #1a535c !important;
      border: solid 2px #1A535C;
      background-color: white;
      width: 8rem;
      border-radius: 15px;
      /* Add other styles specific to 320px devices */
    }

    .centered-toggle {
      position: fixed; // Use 'fixed' instead of 'absolute'
      top: 1rem;
      left: 1rem;
      background-color: #1a535c;
      border: none;
      color: white !important;
      z-index: 1001;
      /* Add other styles specific to 320px devices */
    }

    .navbar-toggler {
      background-color: #1a535c;
      border: none;
      color: white !important;
      display: block;
      margin: 0 auto;
    }

    .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 4rem;
      left: 1rem;

      .nav {
        flex-direction: column;
        align-items: center !important;
        width: 100%;
      }

      .navbar-nav .nav-link {
        padding: 0.4rem 0.8rem;
        /* Add other styles specific to 320px devices */
      }
    }
  }

}


@media only screen and (max-width: 320px) {
  .navbar-wrapper {
    padding: 0.5rem;
    position: relative !important;
    padding: 1rem;
    height: 1rem;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    /* Add other styles specific to 320px devices */

    .navbar {
      flex-direction: column;
      margin: 0 auto;
      background-color: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      /* Add other styles specific to 320px devices */
    }

    .nav-link {
      display: flex !important;
      justify-content: center !important;
      font-size: 1rem !important;
      margin: 0.1rem !important;
      color: #1a535c !important;
      background-color: white;
      width: 8rem;
      border-radius: 15px;
      /* Add other styles specific to 320px devices */
    }

    .centered-toggle {
      position: fixed; // Use 'fixed' instead of 'absolute'
      top: 1rem;
      left: 1rem;
      background-color: #1a535c;
      border: none;
      color: white !important;
      z-index: 1001;
      /* Add other styles specific to 320px devices */
    }

    .navbar-toggler {
      background-color: #1a535c;
      border: none;
      color: white !important;
      display: block;
      margin: 0 auto;
    }

    .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 4rem;
      left: 1rem;

      .nav {
        flex-direction: column;
        align-items: center !important;
        width: 100%;
      }

      .navbar-nav .nav-link {
        padding: 0.4rem 0.8rem;
        /* Add other styles specific to 320px devices */
      }
    }
  }
}
