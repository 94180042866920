.App {
  background-color: #1A535C;
  min-height: auto;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #1A535C;
  border-radius: 10px;
}



