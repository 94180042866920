@media only screen and (max-width: 1792px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 35rem;
  }

  .image-container img {
    width: 30%;
  }
}

@media only screen and (max-width: 1680px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 31rem;
  }

  .image-container img {
    width: 30%;
  }
}

@media only screen and (max-width: 1528px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 26.5rem;
  }

  .image-container img {
    width: 20%;
  }
}

@media only screen and (max-width: 1366px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 21.5rem;
  }

  .image-container img {
    width: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 11rem;
  }

  .image-container img {
    width: 40%;
  }
}

@media only screen and (max-width: 820px) {
  .yek-social {
    width: 650px;
    height: 250px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: 4.3rem;
  }

  .image-container img {
    width: 40%;
  }
}

@media only screen and (max-width: 430px) {
  .yek-social {
    width: 400px;
    height: 150px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-bottom: 4rem;
    margin-left: 0.2rem !important;

    &__list {
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }

    &__item {
      width: 30%;
      height: 100%;
      border-radius: 9px;
    }

    &__button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
      text-align: center;
      transition: 0.5s 0.15s;
      position: relative;
      transform: perspective(1000px) rotate(-25deg) skew(25deg);
    }
  }

  .yek-social__button::after {
    height: 12px;
    bottom: -12px;
    left: -5.5px;
  }

  .yek-social__button::before {
    width: 12px;
    top: 5.5px;
    left: -11px;
  }

  .image-container img {
    width: 60%;
  }

  .contact-header {
    font-size: 1.5rem !important;
  }

  .yek-social__icon {
    font-size: 1em;
  }
}

@media only screen and (max-width: 430px) {
  .yek-social {
    margin-left: -0.5rem;
  }
}

@media only screen and (max-width: 393px) {
  .yek-social {
    width: 400px;
    height: 150px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-bottom: 2rem;
    margin-left: -1.2rem !important;

    &__list {
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }

    &__item {
      width: 25%;
      height: 100%;
      border-radius: 9px;
    }

    &__button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
      text-align: center;
      transition: 0.5s 0.15s;
      position: relative;
      transform: perspective(1000px) rotate(-25deg) skew(25deg);
    }
  }

  .image-container img {
    width: 40%;
  }

  .contact-header {
    font-size: 1rem !important;
  }

  .yek-social__icon {
    font-size: 1em;
  }
}

@media only screen and (max-width: 390px) {
  .yek-social {
    width: 400px;
    height: 150px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-bottom: 2rem;
    margin-left: -1.2rem !important;

    &__list {
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }

    &__item {
      width: 25%;
      height: 100%;
      border-radius: 9px;
    }

    &__button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
      text-align: center;
      transition: 0.5s 0.15s;
      position: relative;
      transform: perspective(1000px) rotate(-25deg) skew(25deg);
    }
  }

  .image-container img {
    width: 40%;
  }

  .contact-header {
    font-size: 1rem !important;
  }

  .yek-social__icon {
    font-size: 1em;
  }
}

@media only screen and (max-width: 360px) {
  .yek-social {
    width: 400px;
    height: 150px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: -2rem;

    &__list {
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }

    &__item {
      width: 25%;
      height: 100%;
      border-radius: 9px;
    }

    &__button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
      text-align: center;
      transition: 0.5s 0.15s;
      position: relative;
      transform: perspective(1000px) rotate(-25deg) skew(25deg);
    }
  }

  .image-container img {
    width: 40%;
  }

  .contact-header {
    font-size: 1rem !important;
  }

  .yek-social__icon {
    font-size: 1em;
  }
}

@media only screen and (max-width: 320px) {
  .yek-social {
    width: 400px;
    height: 150px;
    padding: 9px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10rem;
    margin-left: -3rem;

    &__list {
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }

    &__item {
      width: 25%;
      height: 100%;
      border-radius: 9px;
    }

    &__button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
      text-align: center;
      transition: 0.5s 0.15s;
      position: relative;
      transform: perspective(1000px) rotate(-25deg) skew(25deg);
    }
  }

  .image-container img {
    width: 40%;
  }

  .contact-header {
    font-size: 1rem !important;
  }

  .yek-social__icon {
    font-size: 1em;
  }
}
