@media only screen and (min-height: 600px) and (max-height: 736px) {
  .testimonials {
    min-height: 370vh;
  }
}

@media only screen and (max-width: 1680px) {
  .testimonials {
    height: 290vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: -1%;
      margin-left: -4rem;
      margin-top: -8rem;
      width: 160% !important;
    }

    p {
      font-size: 1.7rem !important;
    }

  }

  .testimonial-image {
    margin-top: 9rem;
    margin-left: 3rem;
  }

  .testimonial-image2 {
    margin-left: -4rem !important;
    margin-top: 13rem !important;
  }

  .testimonial-image3 {
    margin-top: 32rem;
    margin-left: 5rem;
  }

  .mine {
    .message {
      margin-right: 6%;
      margin-top: -7rem;
      margin-left: 6rem;
    }

    p {
      font-size: 1.7rem !important;
    }
  }

  .others {
    .message {
      margin-left: 8%; 
      margin-right: 1rem;
      width: 45%;
    }

    p {
      font-size: 1.7rem !important;
    }
  }
}

@media only screen and (max-width: 1528px) {
  .testimonials {
    height: 390vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: -1%;
      margin-left: -4rem;
      margin-top: -8rem;
      width: 160% !important;
    }

    p {
      font-size: 1.7rem !important;
    }

  }

  .testimonial-image {
    margin-top: 9rem;
    margin-left: 3rem;
  }

  .testimonial-image2 {
   position: relative;
   margin-right: 22rem;
  }

  .testimonial-image3 {
    margin-top: 32rem;
    margin-left: 5rem;
  }

  .mine {
    .message {
      margin-right: 6%;
      margin-top: -7rem;
      margin-left: 6rem;
    }

    p {
      font-size: 1.7rem !important;
    }
  }

  .others {
    .message {
      margin-left: 8%; 
      margin-right: 1rem;
      width: 45%;
    }

    p {
      font-size: 1.7rem !important;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .testimonials {
    height: 280vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: -1%;
      margin-left: -4rem;
      margin-top: -8rem;
      width: 160% !important;
    }

    p {
      font-size: 1.7rem !important;
    }

  }

  .testimonial-image {
    margin-top: 10rem;
    margin-left: 3rem;
  }

  .testimonial-image2 {
    margin-left: -4rem !important;
    margin-top: 14rem !important;
  }

  .testimonial-image3 {
    margin-top: 35rem;
    margin-left: 3rem;
  }

  .mine {
    .message {
      margin-right: 6%;
      margin-top: -7rem;
      margin-left: 6rem;
    }

    p {
      font-size: 1.7rem !important;
    }
  }

  .others {
    .message {
      margin-left: 8%; 
      margin-right: 1rem;
      width: 45%;
    }

    p {
      font-size: 1.7rem !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .testimonials {
    height: 200vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: -1%;
      margin-left: -4rem;
      margin-top: -8rem;
      width: 160% !important;
    }

    p {
      font-size: 1.3rem !important;
    }

  }

  .testimonial-image {
    margin-top: 8rem;
    margin-left: 3rem;
  }

  .testimonial-image2 {
    margin-left: -2rem !important;
    margin-top: 14rem !important;
  }

  .testimonial-image3 {
    margin-top: 27rem;
    margin-left: 8rem;
  }

  .mine {
    .message {
      margin-left: -4%;
      margin-top: -7rem;
      margin-left: 2rem;
    }

    p {
      font-size: 1.3rem !important;
    }
  }

  .others {
    .message {
      margin-left: 21%; 
      margin-right: 1rem;
      width: 45%;
    }

    p {
      font-size: 1.3rem !important;
    }
  }
}

@media only screen and (max-width: 820px) {
  .testimonials {
    height: 280vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: -1%;
      margin-left: -4rem;
      margin-top: -8rem;
      width: 160% !important;
    }

    p {
      font-size: 1.3rem !important;
    }

  }

  .testimonial-image {
    margin-top: 21rem;
    margin-left: 3rem;
  }

  .testimonial-image2 {
    margin-left: 1rem !important;
    margin-top: 28rem !important;
  }

  .testimonial-image3 {
    margin-top: 35rem;
    margin-left: 3rem;
  }

  .mine {
    .message {
      margin-left: -4%;
      margin-top: -7rem;
      margin-left: 2rem;
    }

    p {
      font-size: 1.3rem !important;
    }
  }

  .others {
    .message {
      margin-left: 21%; 
      margin-right: 1rem;
      width: 45%;
    }

    p {
      font-size: 1.3rem !important;
    }
  }
}

@media only screen and (max-width: 430px) {
  .testimonials {
    height: 370vh !important;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: 10%;
      margin-left: -21.5rem;
      margin-top: 1rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .testimonial-image {
    margin-top: 29rem;
    margin-left: 6.5rem;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image2 {
    margin-left: -17.3rem !important;
    margin-top: 27rem !important;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image3 {
    margin-top: 34rem;
    margin-left: 6.7rem;
    width: 13rem;
    height: 13rem;
  }

  .mine {
    .message {
      margin-left: 4rem;
      margin-top: -7rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .others {
    .message {
      margin-left: -1rem; // This was changed from margin-left
      margin-top: -10rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }
}

@media only screen and (max-width: 410px) {
  .yours {
    .message {
      margin-left: -21rem;
    }
  }

  .testimonial-image {
    margin-left: 5.5rem;
  }

  .testimonial-image3 {
    margin-left: 5.7rem;
  }
}

@media only screen and (max-width: 393px) {
  .testimonials {
    height: 430vh !important;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: 10%;
      margin-left: -21rem;
      margin-top: 1rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .testimonial-image {
    margin-top: 29rem;
    margin-left: 5rem;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image2 {
    margin-left: -17.5rem !important;
    margin-top: 26rem !important;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image3 {
    margin-top: 33rem;
    margin-left: 5rem;
    width: 13rem;
    height: 13rem;
  }

  .mine {
    .message {
      margin-left: 2.7rem;
      margin-top: -7rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .others {
    .message {
      margin-left: -2rem; // This was changed from margin-left
      margin-top: -10rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }
}

@media only screen and (max-width: 390px) {
  .testimonials {
    height: 440vh !important;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: 10%;
      margin-left: -21rem;
      margin-top: 1rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .testimonial-image {
    margin-top: 29rem;
    margin-left: 5rem;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image2 {
    margin-left: -17.5rem !important;
    margin-top: 26rem !important;
    width: 13rem;
    height: 13rem;
  }

  .testimonial-image3 {
    margin-top: 33rem;
    margin-left: 5rem;
    width: 13rem;
    height: 13rem;
  }

  .mine {
    .message {
      margin-left: 2.7rem;
      margin-top: -7rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  .others {
    .message {
      margin-left: -2rem; // This was changed from margin-left
      margin-top: -10rem;
      width: 18rem;
    }

    p {
      width: 15rem;
      font-size: 1.2rem !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .testimonials {
    height: 670vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: 10%;
      margin-left: -6rem;
      margin-top: 1rem;
    }
  }

  .testimonial-image {
    margin-top: 28rem;
    width: 10rem;
    height: 10rem;
  }

  .testimonial-image2 {
    margin-left: 0rem !important;
    margin-top: 65rem !important;
    width: 10rem;
    height: 10rem;
  }

  .testimonial-image3 {
    margin-top: 62rem;
    margin-left: 0rem;
    width: 10rem;
    height: 10rem;
  }

  .mine {
    .message {
      margin-left: 4%;
      margin-top: -7rem;
    }
  }

  .others {
    .message {
      margin-left: 24%; // This was changed from margin-left
      margin-top: -10rem;
    }
  }
}

@media only screen and (max-width: 320px) {
  .testimonials {
    height: 670vh;
  }

  .yours {
    align-items: flex-start;

    .message {
      margin-right: 10%;
      margin-left: -6rem;
      margin-top: -8rem;
    }
  }

  .testimonial-image {
    margin-top: 28rem;
    width: 10rem;
    height: 10rem;
  }

  .testimonial-image2 {
    margin-left: 0rem !important;
    margin-top: 65rem !important;
    width: 10rem;
    height: 10rem;
  }

  .testimonial-image3 {
    margin-top: 62rem;
    margin-left: 0rem;
    width: 10rem;
    height: 10rem;
  }

  .mine {
    .message {
      margin-left: 4%;
      margin-top: -7rem;
    }
  }

  .others {
    .message {
      margin-left: 24%; // This was changed from margin-left
      margin-top: -10rem;
    }
  }
}
