@media only screen and (min-height: 664px) and (max-height: 730px) {
  .hero-section {
    min-height: 280vh !important;
    overflow: fixed;
  }

  .hero-image {
    width: 10rem;
    height: 10rem;
    margin: 25%;
    margin-bottom: 1.9rem !important;
    padding: 0;
    margin-left: 5rem;
  }
}

@media only screen and (max-width: 1792px) {
  .hero-section h1 {
    font-size: 6.5rem;
    padding-left: 8rem;
    margin-top: -9rem;
    word-spacing: -0.1rem;
    width: 100%;
  }

  .hero-section h2 {
    font-size: 2.8rem !important;
    padding-left: 13.8rem;
    margin-top: 0rem !important;
  }

  .hero-section p {
    font-size: 2rem;
    width: 80%;
    margin-left: -0.5rem;
    margin-top: 1rem;
  }

  .script {
    margin-left: 18rem !important;
    margin-top: -4rem;
  }

  .script lottie-player {
    width: 230px !important;
    height: 230px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 24rem;
    height: 21rem;
    margin: 18%;
    margin-bottom: 3rem !important;
    padding: 0;
    margin-left: -8rem;
  }

  .d-flex {
    margin-top: 0rem !important;
    margin-left: 7rem;
  }

  .text-1 {
    font-size: 1.8rem;
    margin-left: 6rem !important;
    margin-top: -1.5rem;
  }

  .text-2 {
    font-size: 1.8rem;
    margin-left: 34rem !important;
    margin-top: -1.5rem;
  }

  .text-3 {
    font-size: 1.8rem;
    margin-left: 6rem !important;
    margin-top: -1rem;
  }

  .text-4 {
    font-size: 1.8rem;
    margin-left: 32.2rem !important;
    margin-top: -1rem;
  }

  .hero-image-caption {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 1680px) {
  .hero-section h1 {
    font-size: 6rem;
    padding-left: 8rem;
    margin-top: -12rem;
    word-spacing: -0.1rem;
    width: 100%;
  }

  .hero-section h2 {
    font-size: 2.4rem !important;
    padding-left: 13.8rem;
    margin-top: 0rem !important;
  }

  .hero-section p {
    font-size: 1.8rem;
    width: 80%;
    margin-left: -0.5rem;
    margin-top: -1rem;
  }

  .script {
    margin-left: 17.3rem !important;
    margin-top: -4rem;
  }

  .script lottie-player {
    width: 220px !important;
    height: 220px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 23rem;
    height: 20rem;
    margin: 18%;
    margin-bottom: 3rem !important;
    padding: 0;
    margin-left: -8rem;
  }

  .d-flex {
    margin-top: -2rem !important;
    margin-left: 7rem;
  }

  .text-1 {
    font-size: 1.6rem;
    margin-left: 2.1rem !important;
    margin-top: -1.5rem;
  }

  .text-2 {
    font-size: 1.6rem;
    margin-left: 28.2rem !important;
    margin-top: -1.5rem;
  }

  .text-3 {
    font-size: 1.6rem;
    margin-left: 2.3rem !important;
    margin-top: -1.7rem;
  }

  .text-4 {
    font-size: 1.6rem;
    margin-left: 26.8rem !important;
    margin-top: -1.7rem;
  }

  .hero-image-caption {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 1528px) {
  .hero-section h1 {
    font-size: 5rem;
    padding-left: 11rem;
    margin-top: -5rem;
    word-spacing: -0.1rem;
    width: 120%;
  }

  .hero-section h2 {
    font-size: 2.4rem !important;
    padding-left: 14.5rem;
    margin-top: 0rem !important;
  }

  .hero-section p {
    font-size: 1.8rem;
    width: 80%;
    margin-left: 5rem;
    margin-top: 0rem;
  }

  .script {
    margin-left: 18rem !important;
    margin-top: -7rem;
  }

  .script lottie-player {
    width: 200px !important;
    height: 200px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 16rem;
    height: 16rem;
    margin: 18%;
    margin-bottom: 2rem !important;
    padding: 0;
    margin-left: -6rem;
  }

  .d-flex {
    margin-top: 0rem !important;
    margin-left: 15rem;
  }

  .text-1 {
    font-size: 1.5rem;
    margin-left: 0.9rem !important;
    margin-top: -0.8rem;
  }

  .text-2 {
    font-size: 1.5rem;
    margin-left: 20rem !important;
    margin-top: -0.8rem;
  }

  .text-3 {
    font-size: 1.5rem;
    margin-left: 0.8rem !important;
    margin-top: -6rem;
  }

  .text-4 {
    font-size: 1.5rem;
    margin-left: 18.5rem !important;
    margin-top: -6rem;
  }

  .hero-image-caption {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 1366px) {
  .hero-section h1 {
    font-size: 5rem;
    padding-left: 6rem;
    margin-top: -12rem;
    word-spacing: -0.1rem;
    width: 100%;
  }

  .hero-section h2 {
    font-size: 2.4rem !important;
    padding-left: 12rem;
    margin-top: 0rem !important;
  }

  .hero-section p {
    font-size: 1.8rem;
    width: 80%;
    margin-left: -0.5rem;
    margin-top: -1rem;
  }

  .script {
    margin-left: 15rem !important;
    margin-top: -4rem;
  }

  .script lottie-player {
    width: 220px !important;
    height: 220px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 18rem;
    height: 18rem;
    margin: 18%;
    margin-bottom: 3rem !important;
    padding: 0;
    margin-left: -6rem;
  }

  .d-flex {
    margin-top: -6rem !important;
    margin-left: 0rem;
  }

  .text-1 {
    font-size: 2rem;
    margin-left: -12.4rem !important;
    margin-top: 0.5rem;
  }

  .text-2 {
    font-size: 2rem;
    margin-left: 9.9rem !important;
    margin-top: 0.5rem;
  }

  .text-3 {
    font-size: 2rem;
    margin-left: -12.4rem !important;
    margin-top: -2rem;
  }

  .text-4 {
    font-size: 2rem;
    margin-left: 7.7rem !important;
    margin-top: -2rem;
  }

  .hero-image-caption {
    font-size: 0.66rem !important;
  }
}

@media only screen and (max-width: 1180px) {
  .hero-section h1 {
    font-size: 2.8em;
    padding-left: 2rem;
    margin-top: -15rem;
    word-spacing: -0.1rem;
    width: 90%;
  }

  .hero-section h2 {
    font-size: 1.4rem !important;
    padding-left: 5rem;
    margin-top: -1rem !important;
  }

  .hero-section p {
    font-size: 1rem;
    width: 60%;
    margin-left: -2.5rem;
    margin-top: -1rem;
  }

  .script {
    margin-left: 5rem !important;
    margin-top: -12rem;
  }

  .script lottie-player {
    width: 190px !important;
    height: 190px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 13rem;
    height: 13rem;
    margin: 18%;
    margin-bottom: 1.9rem !important;
    padding: 0;
    margin-left: -4.5rem;
  }

  .d-flex {
    margin-top: -6rem !important;
    margin-left: -11rem;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: -33.8rem !important;
    margin-top: 13rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: -18.3rem !important;
    margin-top: 12.9rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: -33.8rem !important;
    margin-top: 4.6rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: -19.6rem !important;
    margin-top: 4.6rem;
  }

  .hero-image-caption {
    font-size: 0.66rem !important;
  }
}

@media only screen and (max-width: 1024px) {
  .hero-section h1 {
    font-size: 4rem;
    padding-left: 2.8rem;
    margin-top: -13rem;
    word-spacing: -0.1rem;
    width: 90%;
  }

  .hero-section h2 {
    font-size: 1.4rem !important;
    padding-left: 9rem;
    margin-top: -1rem !important;
  }

  .hero-section p {
    font-size: 1.3rem;
    width: 60%;
    margin-left: 0.3rem;
    margin-top: 2rem;
  }

  .script {
    margin-left: 8.5rem !important;
    margin-top: -7rem;
  }

  .script lottie-player {
    width: 210px !important;
    height: 210px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 15rem;
    height: 15rem;
    margin: 18%;
    margin-bottom: 1.9rem !important;
    padding: 0;
    margin-left: -5rem;
  }

  .d-flex {
    margin-top: -6rem !important;
    margin-left: -5rem;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: -24.2rem !important;
    margin-top: 17.5rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: -6.2rem !important;
    margin-top: 17.5rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: -24.2rem !important;
    margin-top: 11rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: -7.6rem !important;
    margin-top: 11rem;
  }

  .hero-image-caption {
    font-size: 0.66rem !important;
  }
}

@media only screen and (max-width: 820px) {
  .hero-section h1 {
    font-size: 2.8em;
    padding-left: 2rem;
    margin-top: -15rem;
    word-spacing: -0.1rem;
    width: 90%;
  }

  .hero-section h2 {
    font-size: 1.4rem !important;
    padding-left: 5rem;
    margin-top: -1rem !important;
  }

  .hero-section p {
    font-size: 1rem;
    width: 60%;
    margin-left: -2.5rem;
    margin-top: -1rem;
  }

  .script {
    margin-left: 5rem !important;
    margin-top: -12rem;
  }

  .script lottie-player {
    width: 190px !important;
    height: 190px !important;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    left: 60rem;
  }

  .hero-image {
    width: 13rem;
    height: 13rem;
    margin: 18%;
    margin-bottom: 1.9rem !important;
    padding: 0;
    margin-left: -4.5rem;
  }

  .d-flex {
    margin-top: -6rem !important;
    margin-left: -11rem;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: -33.8rem !important;
    margin-top: 13rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: -18.3rem !important;
    margin-top: 12.9rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: -33.8rem !important;
    margin-top: 4.6rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: -19.6rem !important;
    margin-top: 4.6rem;
  }

  .hero-image-caption {
    font-size: 0.66rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .hero-section h1 {
    font-size: 3em;
    padding-left: 3rem;
    margin-top: -2rem;
  }

  .hero-section h2 {
    font-size: 1.5em;
    padding-left: 4rem;
  }

  .hero-text {
    min-width: 200px;
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4 {
    font-size: 1.5rem;
    left: 2rem;
    top: 1rem;
  }

  .hero-image {
    width: 100%;
  }

  .d-flex {
    flex-direction: column;
  }
}

@media only screen and (max-width: 430px) {
  .hero-section {
    height: 260vh !important;
  }

  .hero-section h1 {
    font-size: 3em;
    padding-left: 3.2rem;
    margin-top: 10rem;
    margin-bottom: -2rem;
    word-spacing: -0.1rem;
    width: 100%;
  }

  .hero-section h2 {
    font-size: 1.2rem !important;
    padding-left: 7.3rem;
    width: 200% !important;
    margin-top: 2rem !important;
    margin-bottom: -2rem;
  }

  .hero-section p {
    font-size: 1rem !important;
    width: 100%;
    margin-left: -3.4rem;
    margin-top: 2rem;
  }

  .script {
    margin-left: 7.9rem !important;
    margin-top: -9rem;
  }

  .script lottie-player {
    width: 150px !important;
    height: 150px !important;
  }

  .d-flex {
    margin-top: 57rem !important;
  }

  .hero-image {
    width: 19rem;
    height: 16rem;
    margin-left: -17.6rem !important;
    margin-bottom: 2rem !important;
    padding: 0;
  }

  .hero-image-caption {
    font-size: 0.9rem !important;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: 7.7rem !important;
    margin-top: 44.5rem;
    text-underline-offset: 0.3em;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: 9.5rem !important;
    margin-top: 63.6rem;
    text-underline-offset: 0.3em;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: 7.8rem !important;
    margin-top: 82.5rem;
    text-underline-offset: 0.3em;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: 8.39rem !important;
    margin-top: 101.5rem;
    text-underline-offset: 0.3em;
  }
}

@media only screen and (max-width: 420px) {
  .hero-section h1 {
    padding-left: 3.7rem;
  }

  .script {
    margin-left: 7.7rem !important;
  }

  .text-1 {
    margin-left: 7.8rem !important;
  }

  .text-2 {
    margin-left: 9.3rem !important;
  }

  .text-3 {
    margin-left: 7.8rem !important;
  }

  .text-4 {
    margin-left: 8.5rem !important;
  }
}

@media only screen and (max-width: 410px) {
  .hero-section h1 {
    padding-left: 3.4rem;
  }

  .hero-section h2 {
    padding-left: 6.6rem;
  }

  .hero-image {
    margin-left: -27.9rem !important;
  }

  .script {
    margin-left: 7.3rem !important;
  }

  .text-1 {
    margin-left: 7.4rem !important;
  }

  .text-2 {
    margin-left: 9rem !important;
  }

  .text-3 {
    margin-left: 7.5rem !important;
  }

  .text-4 {
    margin-left: 8.2rem !important;
  }
}

@media only screen and (max-width: 393px) {
  .hero-section {
    height: 270vh !important;
  }

  .hero-section h1 {
    font-size: 2.7em;
    padding-left: 2.7rem;
    margin-top: 8rem;
    word-spacing: -0.1rem;
    width: 120%;
  }

  .hero-section h2 {
    font-size: 1.2rem !important;
    padding-left: 6rem;
    width: 200% !important;
    margin-top: 1.5rem !important;
  }

  .hero-section p {
    font-size: 1rem !important;
    width: 100%;
    margin-left: -3.2rem;
    margin-top: 1rem;
  }

  .script {
    margin-left: 6.5rem !important;
    margin-top: -5rem;
  }

  .script lottie-player {
    width: 150px !important;
    height: 150px !important;
  }

  .d-flex {
    margin-top: 54rem !important;
  }

  .hero-image {
    width: 18rem;
    height: 15rem;
    margin-left: -16rem !important;
    margin-bottom: 2rem !important;
    padding: 0;
    margin-left: -20rem;
  }

  .hero-image-caption {
    font-size: 0.9rem !important;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: 6.3rem !important;
    margin-top: 41.5rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: 8.3rem !important;
    margin-top: 59.6rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: 6.5rem !important;
    margin-top: 77.6rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: 7.2rem !important;
    margin-top: 95.7rem;
  }
}

@media only screen and (max-width: 390px) {
  .hero-section {
    height: 300vh !important;
  }

  .hero-section h1 {
    font-size: 2.7em;
    padding-left: 2.7rem;
    margin-top: 8rem;
    word-spacing: -0.1rem;
    width: 120%;
  }

  .hero-section h2 {
    font-size: 1.2rem !important;
    padding-left: 5.9rem;
    width: 200% !important;
    margin-top: 1.5rem !important;
  }

  .hero-section p {
    font-size: 1rem !important;
    width: 100%;
    margin-left: -3.2rem;
    margin-top: 1rem;
  }

  .script {
    margin-left: 6.5rem !important;
    margin-top: -5rem;
  }

  .script lottie-player {
    width: 150px !important;
    height: 150px !important;
  }

  .d-flex {
    margin-top: 54rem !important;
  }

  .hero-image {
    width: 18rem;
    height: 15rem;
    margin-left: -16rem !important;
    margin-bottom: 2rem !important;
    padding: 0;
    margin-left: -20rem;
  }

  .hero-image-caption {
    font-size: 0.9rem !important;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: 6.3rem !important;
    margin-top: 41.5rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: 8.3rem !important;
    margin-top: 59.6rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: 6.5rem !important;
    margin-top: 77.6rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: 7.2rem !important;
    margin-top: 95.7rem;
  }
}

@media only screen and (max-width: 360px) {
  .hero-section {
    height: 220vh !important;
  }

  .hero-section h1 {
    font-size: 2.3em;
    padding-left: 3.5rem;
    margin-top: 8rem;
    word-spacing: -0.1rem;
  }

  .hero-section h2 {
    font-size: 1.2rem !important;
    padding-left: 4.7rem;
    width: 200% !important;
    margin-top: -0.3rem !important;
  }

  .hero-section p {
    font-size: 1rem !important;
    width: 100%;
    margin-left: -2.7rem;
    margin-top: -1rem;
  }

  .d-flex {
    margin-top: 34rem !important;
  }

  .hero-image {
    width: 10rem;
    height: 10rem;
    margin-left: -21.9rem !important;
    margin-bottom: 1.6rem !important;
    padding: 0;
    margin-left: -20rem;
  }

  .hero-image-caption {
    font-size: 0.5rem !important;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: 5.4rem !important;
    margin-top: 22rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: 7.2rem !important;
    margin-top: 34.5rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: 5.4rem !important;
    margin-top: 47.2rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: 6rem !important;
    margin-top: 59.8rem;
  }
}

@media only screen and (max-width: 320px) {
  .hero-section {
    height: 220vh !important;
  }

  .hero-section h1 {
    font-size: 2.3em;
    padding-left: 2.5rem;
    margin-top: 8rem;
    word-spacing: -0.1rem;
  }

  .hero-section h2 {
    font-size: 1.2rem !important;
    padding-left: 3.7rem;
    width: 200% !important;
    margin-top: 0rem !important;
  }

  .hero-section p {
    font-size: 1rem !important;
    width: 100%;
    margin-left: -2.7rem;
    margin-top: -1rem;
  }

  .d-flex {
    margin-top: 34rem !important;
  }

  .hero-image {
    width: 10rem;
    height: 10rem;
    margin-left: -21rem !important;
    margin-bottom: 1.6rem !important;
    padding: 0;
    margin-left: -20rem;
  }

  .hero-image-caption {
    font-size: 0.5rem !important;
  }

  .text-1 {
    font-size: 1.3rem;
    margin-left: 4.7rem !important;
    margin-top: 22rem;
  }

  .text-2 {
    font-size: 1.3rem;
    margin-left: 6.5rem !important;
    margin-top: 34.5rem;
  }

  .text-3 {
    font-size: 1.3rem;
    margin-left: 4.8rem !important;
    margin-top: 47.2rem;
  }

  .text-4 {
    font-size: 1.3rem;
    margin-left: 5.2rem !important;
    margin-top: 59.8rem;
  }
}
