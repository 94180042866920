@media only screen and (min-height: 664px) and (max-height: 746px) {

  .container {
    min-height: 140vh;
  }

}

@media only screen and (max-width: 1528px) {

  .container {
      height: 180vh !important;
  }


}

@media only screen and (max-width: 1024px) {

  .container {
      height: 80vh !important;
  }

  .client-image {
    max-width: 40rem !important; /* Adjust size for mobile */
  }

  h3 {
    font-size: 3rem !important; /* Adjust font size for mobile */
  }

  h2 {
      font-size: 3rem !important;
      margin-top: 2rem !important;
  }

  p {
    font-size: 1rem !important; /* Adjust paragraph size for mobile */
    
  }
}

@media only screen and (max-width: 820px) {

    .container {
        height: 100vh !important;
    }

    .client-image {
      max-width: 40rem !important; /* Adjust size for mobile */
    }
  
    h3 {
      font-size: 3rem !important; /* Adjust font size for mobile */
    }

    h2 {
        font-size: 3rem !important;
        margin-top: 2rem !important;
    }
  
    p {
      font-size: 1rem !important; /* Adjust paragraph size for mobile */
      
    }
  }

  @media only screen and (max-width: 430px) {

    .container {
      height: 100vh !important;
  }

  .client-image {
    max-width: 30rem !important; /* Adjust size for mobile */
  }

  h3 {
    font-size: 2.5rem !important; /* Adjust font size for mobile */
  }

  h2 {
      font-size: 2rem !important;
      margin-top: 2rem !important;
  }

  p {
    font-size: 1rem !important; /* Adjust paragraph size for mobile */
    
  }

  }

  @media only screen and (max-width: 390px) {

    .container {
      height: 100vh !important;
  }

  .client-image {
    max-width: 30rem !important; /* Adjust size for mobile */
  }

  h3 {
    font-size: 2.5rem !important; /* Adjust font size for mobile */
  }

  h2 {
      font-size: 2rem !important;
      margin-top: 2rem !important;
  }

  p {
    font-size: 1rem !important; /* Adjust paragraph size for mobile */
    
  }

  }

  @media only screen and (max-width: 360px) {

    .container {
      height: 150vh !important;
  }

  .client-image {
    max-width: 30rem !important; /* Adjust size for mobile */
  }

  h3 {
    font-size: 2rem !important; /* Adjust font size for mobile */
  }

  h2 {
      font-size: 2rem !important;
      margin-top: 2rem !important;
  }

  p {
    font-size: 0.7rem !important; /* Adjust paragraph size for mobile */
    
  }

  }

  @media only screen and (max-width: 320px) {

    .container {
      height: 150vh !important;
  }

  .client-image {
    max-width: 30rem !important; /* Adjust size for mobile */
  }

  h3 {
    font-size: 2rem !important; /* Adjust font size for mobile */
  }

  h2 {
      font-size: 2rem !important;
      margin-top: 2rem !important;
  }

  p {
    font-size: 0.7rem !important; /* Adjust paragraph size for mobile */
    
  }

  }