.hero-section {
  height: 110vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  background-color: #1A535C;
}

.hero-section {
  display: flex;
  justify-content: space-between;
}

.hero-text {
  flex: 1;
  min-width: 400px;
  flex-shrink: 0;
}


h1 {
  font-family: 'Orbitron', sans-serif;
}

.d-flex {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.hero-section h1, .hero-section h2 {
  font-weight: 500;
  text-align: left;
  color: #FFFFFF;
}

.hero-section h1 {
  width: 120%;
  font-size: 6.5em;
  padding-left: 14rem;
  margin-top: -6rem;
  padding-bottom: 1rem;
  font-weight: 1000;
}

.hero-section h2 {
  font-size: 2.5em;
  padding-left: 23rem;
  font-weight: 400;
}

.text-1 {
  position: absolute;
  top: 2.4rem; // Adjust according to your requirements
  left: 75rem;
  z-index: 2;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline rgb(255,51,102);
  text-underline-offset: 0.2em;
}

.text-2 {
  position: absolute;
  top: 2.4rem; // Adjust according to your requirements
  left: 103rem;
  z-index: 2;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline rgba(34, 139, 34);
  text-underline-offset: 0.2em;
}

.text-3 {
  position: absolute;
  top: 26.7rem; // Adjust according to your requirements
  left: 75rem;
  z-index: 2;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline rgba(143, 0, 255);
  text-underline-offset: 0.2em;
}

.text-4 {
  position: absolute;
  top: 26.7rem; // Adjust according to your requirements
  left: 101rem;
  z-index: 2;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline rgba(255, 160, 0);
  text-underline-offset: 0.2em;
}

.hero-section p {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 2.5em;
  margin: 2rem auto;
  width: 80%;
  min-height: 10rem;
  margin-left: 3.5rem;
}

.paragraph-box {
  padding: 20px;
  width: 120%;
  margin: 30px auto;
  text-align: center;
  margin-left: 2.3rem;;
}

.script {
  margin-left: 24rem;
  margin-top: -5rem;
}

.hero-section .btn {
  font-size: 1.2em;
  padding: 10px 30px;
  background-color: #FFFFFF;
  border: none;
  color: #1A535C;
  transition: all 0.5s ease;
}

.hero-section .btn:hover {
  background-color: #F0F0F0;
  color: #005757;
}

.hero-section .hero-text {
  flex: 1;
}

.hero-image {
  height: 35vh;
  width: calc(43% - 0.4rem);
  top: -3.3rem;
  position: relative;
  left: 7rem;
  margin: 0.2rem;
  transition: all 0.3s ease-in-out;
  transform-origin: right center;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 3rem !important;
  margin-top: 1rem !important;
  margin: 0.4rem;
}

.hero-section .hero-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
}

.hero-section .hero-image.hero-image-1 {
  background: url('../assets/images/foodtruck.jpg') no-repeat center center/cover;
}

.hero-section .hero-image.hero-image-1::after {
  content: "";
  background: linear-gradient(to bottom, rgba(255,51,102,0.5), rgba(0,0,0,0));
  transition: opacity 0.6s ease-in-out;
  border-radius: 25px;
}

.hero-section .hero-image.hero-image-1:hover::after {
  opacity: 0;
}

.hero-section .hero-image.hero-image-2 {
  background: url('../assets/images/gym.jpg') no-repeat center center/cover;
}

.hero-section .hero-image.hero-image-2::after {
  content: "";
  background: linear-gradient(to bottom, rgba(34, 139, 34, 0.5), rgba(0,0,0,0));
  transition: opacity 0.6s ease-in-out;
  border-radius: 25px;
}

.hero-section .hero-image.hero-image-2:hover::after {
  opacity: 0;
}

.hero-section .hero-image.hero-image-3 {
  background: url('../assets/images/rest.jpg') no-repeat center center/cover;
}

.hero-section .hero-image.hero-image-3::after {
  content: "";
  background: linear-gradient(to bottom, rgba(143, 0, 255, 0.5), rgba(0,0,0,0));
  transition: opacity 0.6s ease-in-out;
  border-radius: 25px;
}

.hero-section .hero-image.hero-image-3:hover::after {
  opacity: 0;
}

.hero-section .hero-image.hero-image-4 {
  background: url('../assets/images/website.jpg') no-repeat center center/cover;
}

.hero-section .hero-image.hero-image-4::after {
  content: "";
  background: linear-gradient(to bottom, rgba(255, 160, 0, 0.9), rgba(0,0,0,0));
  transition: opacity 0.6s ease-in-out;
  border-radius: 25px;
}

.hero-section .hero-image.hero-image-4:hover::after {
  opacity: 0;
}

.hero-section .hero-image .hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  text-align: center;
  color: #FFFFFF;
  font-size: 3rem;
}

.hero-section .hero-image:hover .hover-text {
  opacity: 1;
}

.hero-image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); 
  text-align: center;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.hero-image:hover .hero-image-caption {
  transform: translateY(0);
  opacity: 1;
}

.hero-image-1 .hero-image-caption {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),rgba(255, 51, 102, 0.9);
  font-size: 1.3rem;
  height: auto;
}

.hero-image-2 .hero-image-caption {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))rgba(34, 139, 34, 0.9);
  color: white;
  font-size: 1.3rem;
  height: auto;
}

.hero-image-3 .hero-image-caption {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),rgba(143, 0, 255, 0.9);
  font-size: 1.3rem;
  height: auto;
}

.hero-image-4 .hero-image-caption {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),rgba(255, 165, 0, 0.9);
  font-size: 1.3rem;
  height: auto;
  color: white;
}

.text-appear {
  animation: text-appear 0.2s linear forwards;
  opacity: 0;
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-image-1:hover {
  cursor: pointer;
  box-shadow: -10px 10px 20px rgba(255, 51, 102, 0.5), -6px 6px 6px rgba(0,0,0,0.23);
  transform: scale(1.01);
}

.hero-image-3:hover {
  cursor: pointer;
  box-shadow: -10px 10px 20px rgba(143, 0, 255, 0.5), -6px 6px 6px rgba(0,0,0,0.23);
  transform: scale(1.01);
}

.hero-image-2:hover {
  cursor: pointer;
  box-shadow: 10px 10px 20px rgba(34, 139, 34, 0.5), 6px 6px 6px rgba(0,0,0,0.23);
  transform: scale(1.01);
}
.hero-image-4:hover {
  cursor: pointer;
  box-shadow: 10px 10px 20px rgba(255, 165, 0, 0.5), 6px 6px 6px rgba(0,0,0,0.23);
  transform: scale(1.01);
}



@media (max-width: 576px) {

  .hero-section {
    width: 90%;
  }

  .hero-section h1 {
    font-size: 2em;
    padding-left: 11rem;
    margin-top:0rem;
  }
  
  .hero-section h2 {
    font-size: 1.2rem;
    padding-left: 10rem;
  }

  .hero-image {
    height: 50vh;
    width: 14%;
    top: -10rem;
    left: 7.6rem;
  }

}
