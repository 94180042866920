.form-bg {
	height: auto;
  }
  
  a {
	color: #69c;
	text-decoration: none;
	cursor: pointer;
  }
  
  
  .yek-social {
	width: 650px;
	height: 250px;
	padding: 9px;
	border-radius: 9px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-top: 10rem;
	margin-left: 38rem;
  
	&__list,
	&__item,
	&__link,
	&__button {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
  
	&__list {
	  width: 100%;
	  height: auto;   
	  padding-bottom: 35px; 
	}
  
	&__item {
	  width: 32%;
	  height: 100%;
	  border-radius: 9px;
	}
  
	&__button {
	  width: 60px;
	  height: 60px;
	  background-color: #fff;
	  box-shadow: -20px 20px 5px rgba(68, 68, 68, 0.5);
	  text-align: center;
	  transition: 0.5s 0.15s;
	  position: relative;
	  transform: perspective(1000px) rotate(-25deg) skew(25deg);
  
	  &::before,
	  &::after {
		content: "";
		position: absolute;
		background-color: #b1b1b1;
	  }
  
	  &::before {
		width: 21px;
		height: 100%;
		top: 10px;
		left: -20px;
		transition: 0.5s 0.15s;
		transform: rotate(0deg) skewY(-45deg);
	  }
  
	  &::after {
		width: 100%;
		height: 21px;
		bottom: -20px;
		left: -10px;
		transition: 0.8s 0.15s;
		transform: rotate(0deg) skewX(-45deg);
	  }
	}
  
	&__icon {
	  color: #444;
	  font-size: 1.7em;
	  transition-delay: 0.4s;
	}
  
	&__link {
	  &:hover {
		.yek-social__icon {
		  color: #fff;
		}
  
		.yek-social__button {
		  transform: perspective(1000px) rotate(-25deg) skew(25deg) translate(20px, -20px);
		}
  
		.yek-social__button--gmail {
		  background-color: #ea4335;
		  box-shadow: -50px 50px 50px rgba(234, 67, 53, 0.5);
  
		  &::before {
			background-color: #f25c47;
		  }
  
		  &::after {
			background-color: #f98981;
		  }
		}
  
		.yek-social__button--github {
		  background-color: #404840;
		  box-shadow: -50px 50px 50px rgba(64, 72, 64, 0.5);
  
		  &::before {
			background-color: #606960;
		  }
  
		  &::after {
			background-color: #a0b0a0;
		  }
		}
  
		.yek-social__button--linkedin {
		  background-color: #0077b5;
		  box-shadow: -50px 50px 50px rgba(0, 119, 181, 0.5);
  
		  &::before {
			background-color: #0084bf;
		  }
  
		  &::after {
			background-color: #00a0dc;
		  }
		}
	  }
	}
  }

  .pricing-card-container {
	display: flex;
	justify-content: center;
	padding: 2rem;
  
	.card {
	  width: 55rem;
	  border-radius: 10px;
	  border: none;
	  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
	  transition: box-shadow 0.3s ease-in-out;
  
	  &:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	  }
  
	  .card-body {
		text-align: center;
		padding: 2rem;
  
		.card-title {
		  font-size: 2.7rem;
		  margin-bottom: 1.5rem;
		  color: #212529;
		  font-family: 'Orbitron', sans-serif;
		  font-weight: 600;
		}

		.pcard {
			margin-top: 3rem;
			font-weight: 300;
			color: black;
			text-decoration: underline;
		}
  
		.card-text {
		  font-size: 1.2rem;
		  color: #6c757d;
		  font-weight: 500;
  
		  p {
			margin-bottom: 1rem;
		  }
		}
	  }
	}
  }
  
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: 30rem;
    height: auto;
	margin-top: 5rem;
	border-radius: 45px;
}

.contact-header {
    text-align: center;
    margin: 1rem 0;
    font-size: 3rem;
    color: white;
	font-family: 'Orbitron', sans-serif;
	margin-bottom: -10rem;
}
