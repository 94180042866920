$backgroundColor: #FFFFFF;
$navLinkColor: #1A535C;
$navLinkHoverColor: #40E0D0;
$activeColor: #40E0D0;
$transitionTime: 0.5s ease;
$navbarHeight: 3rem;
$fontSize: 1.7rem;
$margin: 1rem;

.navbar-wrapper {
  position: relative;
  background-color: $backgroundColor;
  padding: 1rem;
  margin: 0 32%;
  border-radius: 0 0 45px 45px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1; 
  height: 5.5rem;

  .navbar {
    background-color: $backgroundColor;
    height: $navbarHeight;
    margin: 0;
    right: 0.6rem;

    &-brand, &-nav .nav-link {
      color: $navLinkColor;
      transition: color $transitionTime;
      font-size: $fontSize;
      margin: $margin;
      
      &:hover {
        color: $navLinkHoverColor;
      }
    }
  }

  .active {
    color: $activeColor !important;
  }

  .desktop-nav {
    display: flex;
    align-items: center;
    background-color: $backgroundColor;
    margin: 0;
    right: 0.6rem;

    .nav-link {
      color: $navLinkColor;
      transition: color $transitionTime;
      font-size: $fontSize;
      margin: $margin;
      margin-top: -3rem;
      z-index: 1000;

      &:hover {
        color: $navLinkHoverColor;
      }
    }
  }
}

.hidden {
  display: none !important;
}
